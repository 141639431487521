import { Box, Stack, Typography } from "@mui/material";
import SteppedProgressIndicator from "../SteppedProgressIndicator";
import CircledBackButton from "../CircledBackButton";
import { useTranslation } from "react-i18next";

interface FlowHeaderProps {
  title: string;
  onBackButtonClick?: Function;
  value?: number;
  max?: number;
  isComplete?: boolean;
  isBackButtonVisible?: boolean;
}

export default function FlowHeader({
  title,
  onBackButtonClick,
  value = 0,
  max = 0,
  isComplete = false,
  isBackButtonVisible = true,
}: FlowHeaderProps) {
  const { t } = useTranslation();

  return (
    <>
      <Stack
        justifyContent="center"
        bgcolor="background.paper"
        position="relative"
        textAlign="center"
        sx={{
          minHeight: (theme) => theme.spacing(9),
        }}
      >
        <Box
          sx={{
            left: (theme) => theme.spacing(2),
            position: "absolute",
            top: "50%",
            transform: "translateY(-50%)",
          }}
        >
          {isBackButtonVisible && (
            <CircledBackButton
              onClick={onBackButtonClick ? onBackButtonClick : undefined}
            />
          )}
        </Box>
        <Typography component="h1" sx={{ fontWeight: 700 }}>
          {title}
        </Typography>

        <Typography component="span" variant="subtitle2">
          {t("common.stepCounter", { x: value, y: max })}
        </Typography>
        <Box
          sx={{
            left: 0,
            position: "absolute",
            bottom: 0,
            width: "100%",
          }}
        >
          <SteppedProgressIndicator
            value={value}
            max={max}
            isComplete={isComplete}
          />
        </Box>
      </Stack>
    </>
  );
}
