import { useTranslation } from "react-i18next";
import { Button, Stack, Typography } from "@mui/material";
import theme from "@/theme";
import IconLoader from "@/features/Common/components/IconLoader";
import DefaultDialog, {
  DefaultDialogImplementationProps,
} from "@/features/Common/components/DefaultDialog";

export enum ModalKeys {
  WHAT_IS_AN_UNUID = "WhatIsAnUNUIDModal",
  RSA_ID_OR_PASSPORT = "RSAIDOrPassport",
}

export default function SwitchAuthIdentificationTypeModal({
  isOpen = true,
  onClose = () => {},
  extras,
}: DefaultDialogImplementationProps) {
  const { t } = useTranslation();
  const modalBodyContent = t<any, any, string[]>(
    `CollectIdentificationNumberModal.content` as any,
    {
      returnObjects: true,
    }
  );

  function onCloseModal() {
    if (extras && extras.onCloseButtonClick) {
      return extras.onCloseButtonClick();
    }

    return onClose();
  }

  return (
    <DefaultDialog
      isOpen={isOpen}
      onClose={onClose}
      animate={true}
      label={t(`${extras.modalLabelKey}.title` as any)}
    >
      <Stack spacing={4} textAlign="center">
        <IconLoader
          icon="FaceWithQuestionMarkIcon"
          color="primary"
          sx={{
            fontSize: theme.spacing(6),
            alignSelf: "center",
          }}
        />

        <Stack spacing={2}>
          <Typography variant="h2">
            {t(`${extras.modalLabelKey}.title` as any)}
          </Typography>

          {(Array.isArray(modalBodyContent) ? modalBodyContent : []).map(
            (text: string, index: number) => (
              <Typography color="neutral" key={String(text + index)}>
                {text}
              </Typography>
            )
          )}

          <Button
            onClick={extras.onRatherSignInWithDifferentAuthType}
            variant="text"
          >
            {t(
              `${extras.modalLabelKey}.signInWith${extras.authTypeLabel}` as any
            )}
          </Button>

          <Button size="small" onClick={() => onCloseModal()}>
            {t("common.close")}
          </Button>
        </Stack>
      </Stack>
    </DefaultDialog>
  );
}
