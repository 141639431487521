import { forwardRef } from "react";
import { TransitionProps } from "@mui/material/transitions";
import { Slide } from "@mui/material";

export default forwardRef(function SlideWithTransition(
  props: TransitionProps & {
    children: React.ReactElement;
    direction?: "up" | "down" | "left" | "right";
    onExited?: Function;
  },
  ref: React.Ref<unknown>
) {
  return <Slide ref={ref} {...props} />;
});
