import { useTranslation } from "react-i18next";
import { Button, Stack, Typography } from "@mui/material";
import ProcessIndicatorIcon from "@/features/Common/components/ProcessIndicatorIcon";
import { trackEvent } from "@/services/analytics-adapter";
import { getConfigurationVariable } from "@/services/configuration-adapter";
import {
  IdentityDocumentType,
  formatPhoneNumber,
} from "@/services/core-api-adapter";
import { defaultFullscreenPageStyling } from "@/theme";
import { replaceSubjectWithString } from "@/utils";

interface AuthGetMemberErrorProps {
  identificationNumber?: {
    identificationType: string;
    identificationValue: string;
  };
  phoneNumber: { globalSubscriberNumber: string; countryCode: string };
  UNUID?: string;
  authenticationType?: string;
  tryAgain: () => void;
}

export default function AuthGetMemberError({
  identificationNumber,
  phoneNumber,
  UNUID,
  authenticationType,
  tryAgain,
}: AuthGetMemberErrorProps) {
  const { t } = useTranslation();

  function onTryAgainButtonClick() {
    tryAgain();
  }

  function onGetSupportLinkClick() {
    trackEvent({
      event: "action.supportRequested",
      source: "auth flow getMember error",
    });
  }

  function getIdentificationNumber() {
    if (authenticationType === IdentityDocumentType.IdNumber) {
      return identificationNumber?.identificationValue;
    }

    if (authenticationType === IdentityDocumentType.PassportNumber) {
      return identificationNumber?.identificationValue;
    }

    if (authenticationType === IdentityDocumentType.UNUID) {
      return UNUID;
    }
    return "";
  }

  return (
    <Stack
      justifyContent="flex-end"
      sx={{
        ...defaultFullscreenPageStyling,
      }}
    >
      <Stack spacing={2} justifyContent="space-between">
        <Stack sx={{ opacity: 0.5, textAlign: "center", mb: 6 }}>
          <ProcessIndicatorIcon type="error" height={90} width={90} />
        </Stack>
        <Stack spacing={3} sx={{ pb: 3 }}>
          <Typography variant="h2">{t("AuthGetMemberError.title")}</Typography>
          <Typography variant="body1" color="neutral">
            {t("AuthGetMemberError.subtitle")}
          </Typography>
          <Stack>
            {authenticationType && (
              <Typography variant="h4">
                {t(
                  `AuthGetMemberError.${replaceSubjectWithString(
                    authenticationType,
                    IdentityDocumentType.UNUID,
                    "UNUID"
                  )}.label` as any
                )}{" "}
                {getIdentificationNumber()}
              </Typography>
            )}
            <Typography variant="h4">
              {t("AuthGetMemberError.phoneNumber.label")}{" "}
              {formatPhoneNumber(
                phoneNumber.globalSubscriberNumber,
                phoneNumber.countryCode
              )}
            </Typography>
          </Stack>
          <Typography variant="body1" color="neutral">
            {t("AuthGetMemberError.reportIssueLabel")}
          </Typography>
        </Stack>

        <Stack direction="row" spacing={2}>
          <Button fullWidth onClick={onTryAgainButtonClick} variant="outlined">
            {t("common.tryAgain")}
          </Button>
          <Button
            component="a"
            href={getConfigurationVariable("VITE_APP_UNU_HEALTH_SUPPORT_URI")}
            onClick={onGetSupportLinkClick}
            target="_blank"
            fullWidth
            variant="contained"
          >
            {t("common.supportLink")}
          </Button>
        </Stack>
      </Stack>
    </Stack>
  );
}
