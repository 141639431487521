import React from "react";
import { useTranslation } from "react-i18next";
import { IconButton } from "@mui/material";
import IconLoader from "../IconLoader";

export interface Props {
  onClick: React.MouseEventHandler<HTMLButtonElement>;
}

export default function ModalCloseButton(props: Props) {
  const { t } = useTranslation();

  return (
    <IconButton
      aria-label={t("common.modalCloseButton")}
      onClick={props.onClick}
      sx={{
        padding: 2,
        position: "absolute",
        right: 0,
        top: 0,
      }}
    >
      <IconLoader icon="CrossIcon" />
    </IconButton>
  );
}
