import React, { PropsWithChildren, useEffect, useState } from "react";
import { Dialog, Stack, SxProps, Theme, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import ModalCloseButton from "../ModalCloseButton";
import SlideWithTransition from "../SlideWithTransition";
import MaxWidthContainer from "../MaxWidthContainer";
import { TransitionProps } from "@mui/material/transitions";

export interface DefaultDialogProps {
  isOpen: boolean;
  onClose: Function;
  title?: string;
  label?: string;
  actionButtons?: React.ReactNode;
  animate?: boolean;
  isFullscreen?: boolean;
  direction?: "up" | "down" | "left" | "right";
  closeButton?: boolean;
  paperStyleProps?: SxProps<Theme>;
  fullWidth?: boolean;
  isConstrainedToMaxWidthContainer?: boolean;
}

interface ConditionalWrapperProps {
  condition: boolean;
  wrapper: Function;
  children: any;
}
function ConditionalWrapper({
  condition,
  wrapper,
  children,
}: ConditionalWrapperProps) {
  return condition ? wrapper(children) : children;
}

export interface DefaultDialogImplementationProps {
  isOpen: boolean;
  onClose: Function;
  extras?: any;
}

function MaxWidthContainerWrapper(children: any) {
  return <MaxWidthContainer>{children}</MaxWidthContainer>;
}

export default function DefaultDialog({
  children,
  isOpen,
  onClose,
  title,
  label,
  actionButtons,
  isFullscreen = false,
  animate = false,
  direction = "up",
  closeButton = true,
  paperStyleProps,
  fullWidth = false,
  isConstrainedToMaxWidthContainer = false,
}: PropsWithChildren<DefaultDialogProps>) {
  const { t } = useTranslation();
  const [localIsOpen, setLocalIsOpen] = useState(isOpen);

  useEffect(() => {
    setLocalIsOpen(isOpen);
  }, [isOpen]);

  function onCloseButtonClick() {
    setLocalIsOpen(false);
  }

  function onExited() {
    onClose();
  }

  return (
    <Dialog
      PaperProps={{
        sx: {
          borderRadius: 4,
          p: 5,
          ...paperStyleProps,
        },
      }}
      fullWidth={fullWidth}
      maxWidth="md"
      open={localIsOpen}
      fullScreen={isFullscreen}
      onClose={onCloseButtonClick}
      aria-label={label ? label : title}
      TransitionComponent={animate ? SlideWithTransition : undefined}
      TransitionProps={{ direction, onExited } as TransitionProps}
    >
      <ConditionalWrapper
        condition={isConstrainedToMaxWidthContainer}
        wrapper={MaxWidthContainerWrapper}
      >
        {closeButton && (
          <ModalCloseButton
            aria-label={t("common.modalCloseButton")}
            onClick={onCloseButtonClick}
          />
        )}

        <Stack spacing={2} sx={{ height: "100%" }}>
          {title && (
            <Typography
              variant="h3"
              align="center"
              sx={{ fontWeight: 700, mx: 4 }}
            >
              {title}
            </Typography>
          )}

          {children}

          {actionButtons}
        </Stack>
      </ConditionalWrapper>
    </Dialog>
  );
}
