const mod10CheckDigit = (value: string) => {
  const doubledSum: any = value
    .split("")
    .map((val, index) => {
      if ((index + value.length) % 2 !== 0) {
        const double = Number.parseInt(val, 10) * 2;
        return double > 9 ? double - 9 : double;
      }

      return val;
    })
    .reduce((acc: any, curr: any) => {
      return Number.parseInt(acc, 10) + Number.parseInt(curr, 10);
    }, 0);

  return (doubledSum * 9) % 10;
};

const isValidDate = (dateString: string) => {
  const dateObject: Date = new Date(dateString);
  const [year, month, day] = dateString
    .split("/")
    .map((dateElement) => parseInt(dateElement, 10));
  const zeroIndexedMonthValue: number = month - 1;

  if (
    dateObject.getFullYear() === year &&
    dateObject.getMonth() === zeroIndexedMonthValue &&
    dateObject.getDate() === day
  ) {
    return true;
  }
  return false;
};

export function execute(value: string | unknown): string[] {
  const errorMessages = {
    INVALID_TYPE: "INVALID_TYPE",
    INCORRECT_FORMAT: "INCORRECT_FORMAT",
    INVALID_DATE: "INVALID_DATE",
    INVALID_CHECKSUM: "INVALID_CHECKSUM",
    TOO_SHORT: "TOO_SHORT",
    TOO_LONG: "TOO_LONG",
  };

  const errors: string[] = [];

  if (typeof value !== "string") {
    errors.push(errorMessages.INVALID_TYPE);
    return errors;
  }

  if (value.length < options.minLength) {
    errors.push(errorMessages.TOO_SHORT);
  }

  if (value.length > options.maxLength) {
    errors.push(errorMessages.TOO_LONG);
  }

  const regex = /^\d{2}[0-1]\d[0-3]\d\d{4}[0-2]\d{2}$/;
  if (!regex.test(value)) {
    errors.push(errorMessages.INCORRECT_FORMAT);
  }

  const currentYearSuffix = new Date().getFullYear().toString().substring(2, 4);
  const valueYearSuffix = value.substring(0, 2);

  const century = valueYearSuffix < currentYearSuffix ? "20" : "19";
  const year = `${century}${valueYearSuffix}`;
  const month = value.substring(2, 4);
  const day = value.substring(4, 6);

  if (!isValidDate(`${year}/${month}/${day}`)) {
    errors.push(errorMessages.INVALID_DATE);
  }

  const numberToValidate = value.substring(0, 12);
  const checksumDigit = Number.parseInt(value.substring(12, 13), 10);

  if (mod10CheckDigit(numberToValidate) !== checksumDigit) {
    errors.push(errorMessages.INVALID_CHECKSUM);
  }

  return errors;
}

export function isValid(value: string | unknown): boolean {
  return execute(value).length === 0;
}

export const options = {
  maxLength: 13,
  minLength: 13,
};
