import { Box } from "@mui/material";

interface IProps {
  errors: Array<string>;
}

export default function FormInputErrorList({ errors }: IProps) {
  return errors.length > 0 ? (
    <Box
      sx={{
        color: "error.main",
        textAlign: "center",
        " ul li": {
          listStyle: "none",
        },
      }}
    >
      <ul role="alert">
        {errors.map((error, index) => {
          const key = String(index) + String(error);
          return <li key={key}>{error}</li>;
        })}
      </ul>
    </Box>
  ) : null;
}
