function getConfigurationVariableFromCypress() {
  return window.Cypress
    ? Object.keys(window.Cypress.env())
        .filter((key: string) => {
          return key.indexOf("VITE_APP_") === 0;
        })
        .reduce((accumulator: any, key: string) => {
          accumulator[key] = window.Cypress.env(key);
          return accumulator;
        }, {})
    : {};
}

export function getConfigurationVariable(name: string) {
  const combinedConfigurationVariables = {
    ...import.meta.env,
    ...getConfigurationVariableFromCypress(),
  };
  return combinedConfigurationVariables[name];
}
