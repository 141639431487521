import { useEffect } from "react";
import { useMachine } from "@xstate/react";
import {
  authFlowMachine,
  eventNames as authFlowMachineEventNames,
} from "./machine";

import { Navigate, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { Box, Fade, Stack, Typography } from "@mui/material";
import CollectOTP from "@/features/Common/components/CollectOTP";
import CollectPhoneNumber from "@/features/Auth/components/CollectPhoneNumber";
import FlowHeader from "@/features/Common/components/FlowHeader";
import FormInputErrorList from "@/features/Common/components/FormInputErrorList";
import FullscreenLoadingIndicator from "@/features/Common/components/FullscreenLoadingIndicator";
import { InternationalPhoneNumber } from "@/features/Common/components/InternationalPhoneNumberInput";
import {
  trackEvent,
  trackFormEvent,
  AnalyticsEvent,
  trackUserInteraction,
  trackPageViewV2,
  trackApplicationEvent,
} from "@/services/analytics-adapter";
import {
  logout,
  IdentityDocumentType,
  getI18nTranslationKeysFromMessages,
} from "@/services/core-api-adapter";
import {
  isFeatureEnabled,
  FeatureToggleIdentifier,
} from "@/services/feature-toggle-adapter";
import { useGlobalStore, AuthIdentificationType } from "@/store";
import AuthGetMemberError from "../AuthGetMemberError";
import CollectIdentificationNumber from "../CollectIdentificationNumber";
import CollectUNUID from "../CollectUNUID";

export default function AuthFlow() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { state, dispatch } = useGlobalStore();

  const [authFlowMachineState, dispatchAuthFlowMachineEvent] =
    useMachine(authFlowMachine);

  useEffect(() => {
    logout().then(() => {
      dispatch({
        type: "CLEAR_CURRENT_USER",
      });
    });
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [authFlowMachineState]);

  useEffect(() => {
    if (
      isFeatureEnabled(
        FeatureToggleIdentifier.ENABLE_AUTH_IDENTIFICATION_TYPE_SELECTION
      )
    ) {
      if (
        state.userSelectedAuthIdentificationType.value ===
        AuthIdentificationType.UNUID
      ) {
        dispatchAuthFlowMachineEvent({
          type: authFlowMachineEventNames.COLLECT_UNUID,
        });
      } else if (
        state.userSelectedAuthIdentificationType.value ===
        AuthIdentificationType.ZA_ID_OR_INTERNATIONAL_PASSPORT
      ) {
        dispatchAuthFlowMachineEvent({
          type: authFlowMachineEventNames.COLLECT_ZA_ID_OR_INTERNATIONAL_PASSPORT,
        });
      } else {
        dispatchAuthFlowMachineEvent({
          type: authFlowMachineEventNames.GO_BACK,
        });
      }
    } else {
      dispatchAuthFlowMachineEvent({
        type: authFlowMachineEventNames.COLLECT_ZA_ID_OR_INTERNATIONAL_PASSPORT,
      });
    }

    if (authFlowMachineState.context?.getMemberResponse?.memberId) {
      dispatch({
        type: "SET_CURRENT_USER",
        payload: authFlowMachineState.context.getMemberResponse,
      });
      dispatch({
        type: "SET_IS_USER_LOGGED_IN",
        payload: true,
      });
      navigate("/home");
    }

    if (authFlowMachineState.context.loginResponse.authenticationResult) {
      dispatchAuthFlowMachineEvent({
        type: authFlowMachineEventNames.SKIP_OTP_COLLECTION,
      });
    }
  }, [
    authFlowMachineState.context.loginResponse,
    authFlowMachineState.context.getMemberResponse,
    authFlowMachineState.context.getMemberError,
    authFlowMachineState.context?.loginOTPResponseErrors,
    authFlowMachineState.context?.loginResponseErrors,
    dispatch,
    t,
    navigate,
  ]);

  function onCollectIdNumberSubmit(
    identificationType: string,
    identificationValue: string
  ) {
    dispatchAuthFlowMachineEvent({
      type: authFlowMachineEventNames.ID_NUMBER_COLLECTED,
      info: {
        identificationType: identificationType,
        identificationValue: identificationValue,
      },
    });
  }

  function onCollectPassportNumberSubmit(
    identificationType: string,
    identificationValue: string
  ) {
    dispatchAuthFlowMachineEvent({
      type: authFlowMachineEventNames.PASSPORT_NUMBER_COLLECTED,
      info: {
        identificationType: identificationType,
        identificationValue: identificationValue,
      },
    });
  }

  function onCollectPassportOrIdNumberSubmit(
    identificationType: string,
    identificationValue: string
  ) {
    if (identificationType === IdentityDocumentType.IdNumber) {
      onCollectIdNumberSubmit(identificationType, identificationValue);
      trackEvent({
        event: "action.idNumberCollected",
        source: "Auth Flow",
      });
    }

    if (identificationType === IdentityDocumentType.PassportNumber) {
      onCollectPassportNumberSubmit(identificationType, identificationValue);
      trackEvent({
        event: "action.passportNumberCollected",
        source: "Auth Flow",
      });
    }

    trackFormEvent(AnalyticsEvent.FORM_COMPLETE, {
      formName: "Enter ID Number or Passport",
    });
  }

  function onCollectUNUIDSubmit(value: string) {
    dispatchAuthFlowMachineEvent({
      type: authFlowMachineEventNames.UNUID_COLLECTED,
      info: value,
    });

    trackEvent({
      event: "action.UNUIDCollected",
      source: "Auth Flow",
    });

    trackFormEvent(AnalyticsEvent.FORM_COMPLETE, {
      formName: "Enter UNU ID",
    });
  }

  function onCollectPhoneNumberSubmit(phoneNumber: InternationalPhoneNumber) {
    dispatchAuthFlowMachineEvent({
      type: authFlowMachineEventNames.PHONE_NUMBER_COLLECTED,
      info: phoneNumber,
    });

    trackEvent({
      event: "action.phoneNumberCollected",
      source: "Auth Flow",
    });

    trackFormEvent(AnalyticsEvent.FORM_COMPLETE, {
      formName: "Enter cellphone number",
    });
  }

  function onCollectOTPSubmit(otpValue: string) {
    dispatchAuthFlowMachineEvent({
      type: authFlowMachineEventNames.OTP_COLLECTED,
      info: otpValue,
    });
    trackEvent({
      event: "action.OTPCollected",
      source: "Auth Flow",
    });

    trackFormEvent(AnalyticsEvent.FORM_COMPLETE, {
      formName: "Enter OTP",
    });
  }

  function resendOTP() {
    dispatchAuthFlowMachineEvent({
      type: authFlowMachineEventNames.RESEND_OTP,
    });
    trackEvent({
      event: "action.resendOTP",
      source: "Auth Flow",
    });

    trackUserInteraction({
      linkText: "OTP Screen | resendOTP",
      linkIntent: "confirmational",
      linkScope: "button",
    });
  }

  function onBackButtonClick() {
    dispatch({
      type: "CLEAR_WELCOME_SCREEN_LAST_VIEWED",
    });
    dispatchAuthFlowMachineEvent({ type: authFlowMachineEventNames.GO_BACK });
  }

  function tryAgain() {
    logout();
    dispatchAuthFlowMachineEvent({ type: authFlowMachineEventNames.TRY_AGAIN });
  }

  function onToggleAuthTypeHandler() {
    if (
      isFeatureEnabled(
        FeatureToggleIdentifier.ENABLE_AUTH_IDENTIFICATION_TYPE_SELECTION
      )
    ) {
      const authenticationIdType =
        state.userSelectedAuthIdentificationType.value ||
        AuthIdentificationType.ZA_ID_OR_INTERNATIONAL_PASSPORT;

      switch (authenticationIdType) {
        case AuthIdentificationType.UNUID:
          dispatch({
            type: "SET_USER_AUTH_IDENTIFICATION_TYPE",
            payload: {
              userSelectedAuthIdentificationType:
                AuthIdentificationType.ZA_ID_OR_INTERNATIONAL_PASSPORT,
            },
          });

          return dispatchAuthFlowMachineEvent({
            type: authFlowMachineEventNames.COLLECT_ZA_ID_OR_INTERNATIONAL_PASSPORT,
          });

        case AuthIdentificationType.ZA_ID_OR_INTERNATIONAL_PASSPORT:
          dispatch({
            type: "SET_USER_AUTH_IDENTIFICATION_TYPE",
            payload: {
              userSelectedAuthIdentificationType: AuthIdentificationType.UNUID,
            },
          });

          return dispatchAuthFlowMachineEvent({
            type: authFlowMachineEventNames.COLLECT_UNUID,
          });
      }
    } else {
      return false;
    }
  }

  useEffect(() => {
    const selectedAuthType = state.userSelectedAuthIdentificationType.value;

    if (
      authFlowMachineState.matches(
        "collectingZA_ID_OR_INTERNATIONAL_PASSPORT"
      ) &&
      selectedAuthType ===
        AuthIdentificationType.ZA_ID_OR_INTERNATIONAL_PASSPORT
    ) {
      trackPageViewV2({
        pageName: "Auth:Collect identification number",
        pageSubSection1: "Auth",
        pageSubSection2: "Auth:Collect identification number",
        pageCategory: "Auth",
      });
      trackFormEvent(AnalyticsEvent.FORM_START, {
        formName: "Enter ID Number or Passport",
      });
    }

    if (
      authFlowMachineState.matches(
        "collectingZA_ID_OR_INTERNATIONAL_PASSPORT"
      ) ||
      authFlowMachineState.matches("collectingUNUID")
    ) {
      trackApplicationEvent(AnalyticsEvent.APPLICATION_START, {
        applicationName: "Application: auth flow",
        applicationStep: "step 1",
      });
    }

    if (
      authFlowMachineState.matches("exit") ||
      authFlowMachineState.matches("done")
    ) {
      trackApplicationEvent(AnalyticsEvent.APPLICATION_COMPLETE, {
        applicationName: "Application: auth flow",
        applicationStep: "step 8",
      });
    }
  }, [authFlowMachineState]);

  return (
    <Stack
      spacing={2}
      justifyContent="space-between"
      sx={{ height: "100%", overflowX: "hidden" }}
    >
      {authFlowMachineState.matches("gettingMemberErrorGeneric") ? (
        <AuthGetMemberError
          UNUID={authFlowMachineState.context.UNUID}
          identificationNumber={
            authFlowMachineState.context.identificationNumber
          }
          phoneNumber={authFlowMachineState.context.phoneNumber}
          authenticationType={authFlowMachineState.context.authenticationType}
          tryAgain={tryAgain}
        />
      ) : (
        <>
          <FlowHeader
            title={t("AuthFlow.title")}
            onBackButtonClick={onBackButtonClick}
            value={authFlowMachineState.context.currentStepValue}
            max={authFlowMachineState.context.totalSteps}
            isBackButtonVisible={
              authFlowMachineState.context.isBackTransitionAllowed
            }
          />
          <Box sx={{ p: 2 }}>
            <Box sx={{ mb: 2 }}>
              <FormInputErrorList
                errors={getI18nTranslationKeysFromMessages(
                  authFlowMachineState.context.loginResponseErrors
                ).map((translationKey: any) => {
                  return t(translationKey);
                })}
              />
            </Box>

            {authFlowMachineState.matches("collectingUNUID") ? (
              <CollectUNUID
                value={authFlowMachineState.context.UNUID}
                onSubmit={onCollectUNUIDSubmit}
                onSwitchAuthTypeHandler={onToggleAuthTypeHandler}
              />
            ) : (
              <>
                {authFlowMachineState.matches(
                  "collectingZA_ID_OR_INTERNATIONAL_PASSPORT"
                ) && (
                  <Fade in={true}>
                    <div>
                      <CollectIdentificationNumber
                        value={{
                          identificationType:
                            state.userSelectedAuthIdentificationType
                              ?.identityDocumentSubType || "",
                          identificationValue:
                            authFlowMachineState.context.identificationNumber
                              .identificationValue || "",
                        }}
                        onSubmit={onCollectPassportOrIdNumberSubmit}
                        onSwitchAuthTypeHandler={onToggleAuthTypeHandler}
                      />
                    </div>
                  </Fade>
                )}
                {authFlowMachineState.matches(
                  "verifyingIdentificationCredentials"
                ) && (
                  <FullscreenLoadingIndicator isOpen={true} mainIconType="face">
                    <Typography component="p" sx={{ textAlign: "center" }}>
                      {t(
                        "AuthFlow.verifyingIdentificationCredentials.loadingMessage"
                      )}
                    </Typography>
                  </FullscreenLoadingIndicator>
                )}
                {authFlowMachineState.matches("collectingPhoneNumber") && (
                  <Fade in={true}>
                    <div>
                      <CollectPhoneNumber
                        value={authFlowMachineState.context.phoneNumber}
                        onSubmit={onCollectPhoneNumberSubmit}
                      />
                    </div>
                  </Fade>
                )}
                {authFlowMachineState.matches("collectingOTP") && (
                  <Fade in={true}>
                    <div>
                      <CollectOTP
                        errors={getI18nTranslationKeysFromMessages(
                          authFlowMachineState.context.loginOTPResponseErrors
                        ).map((translationKey: any) => {
                          return t(translationKey);
                        })}
                        phoneNumber={authFlowMachineState.context.phoneNumber}
                        onSubmit={onCollectOTPSubmit}
                        resendOTP={resendOTP}
                      />
                    </div>
                  </Fade>
                )}
                {(authFlowMachineState.matches("verifyingOTP") ||
                  authFlowMachineState.matches("resendOTP") ||
                  authFlowMachineState.matches("gettingMember")) && (
                  <FullscreenLoadingIndicator
                    isOpen={true}
                    mainIconType="face"
                  />
                )}
              </>
            )}
            {authFlowMachineState.matches("exit") && (
              <Navigate to="/"></Navigate>
            )}
          </Box>
        </>
      )}
    </Stack>
  );
}
