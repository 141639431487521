import { Box } from "@mui/material";
import IconLoader from "../IconLoader";

interface ProcessIndicatorIconProps {
  type?: "info" | "success" | "error";
  variant?: "" | "outline";
  height?: number;
  width?: number;
}

export default function ProcessIndicatorIcon({
  type = "info",
  variant = "",
  height = 24,
  width = 24,
}: ProcessIndicatorIconProps) {
  const BackgroundComponent = IconLoader;
  const ForegroundIcon = IconLoader;
  const backgroundColor: any = type === "info" ? "primary" : type;
  let foregroundIconColor: any = "info";

  if (variant === "outline") {
    foregroundIconColor = backgroundColor;
  }

  return (
    <Box
      sx={{
        display: "inline-block",
        lineHeight: 0,
        position: "relative",
      }}
      aria-label="Process indicator"
    >
      <BackgroundComponent
        icon={
          variant === "outline"
            ? "HandDrawnCircleOutlineIcon"
            : "HandDrawnCircleIcon"
        }
        color={backgroundColor}
        aria-label="Process indicator background icon"
        sx={{
          height: { height },
          width: { width },
        }}
      />
      <ForegroundIcon
        icon={type === "error" ? "CrossIcon" : "CheckmarkIcon"}
        color={foregroundIconColor}
        aria-label="Process indicator foreground icon"
        sx={{
          height: "50%",
          left: "50%",
          position: "absolute",
          top: "50%",
          transform: "translate(-50%, -50%)",
          width: "50%",
        }}
      />
    </Box>
  );
}
