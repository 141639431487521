import React from "react";
import { Backdrop, Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { convertPxToRem } from "../../../../utils";
import IconLoader from "../IconLoader";

interface FullscreenLoadingIndicatorProps {
  isOpen?: boolean;
  loadingText?: string;
  mainIconType?: "" | "face" | "healthID";
  children?: React.ReactNode;
}

export default function FullscreenLoadingIndicator({
  isOpen = true,
  mainIconType = "",
  loadingText,
  children,
}: FullscreenLoadingIndicatorProps) {
  const { t } = useTranslation();
  let mainIcon = <div></div>;

  switch (mainIconType) {
    case "face":
      mainIcon = (
        <IconLoader
          icon="FaceInverseIcon"
          color="primary"
          aria-label="Face icon"
          sx={{
            fontSize: convertPxToRem(54),
            left: "50%",
            position: "absolute",
            top: "50%",
            transform: "translate(-50%, -50%)",
          }}
        />
      );
      break;
    case "healthID":
      mainIcon = (
        <IconLoader
          icon="HeartWithIDIcon"
          color="primary"
          aria-label="Heart icon"
          sx={{
            fontSize: convertPxToRem(54),
            left: "50%",
            position: "absolute",
            top: "50%",
            transform: "translate(-50%, -50%)",
          }}
        />
      );
      break;
  }

  return isOpen ? (
    <Backdrop
      sx={{
        backgroundColor: "#fff",
        m: 0,
        zIndex: (theme) => theme.zIndex.drawer + 1,
      }}
      open={isOpen}
    >
      <div
        style={{ position: "absolute" }}
        role="progressbar"
        aria-label={t("common.loadingMessage")}
        aria-busy="true"
        aria-live="assertive"
      >
        <IconLoader
          icon="SpinnerIcon"
          className="spin-animation"
          sx={{
            color: "primary.main",
            fontSize: convertPxToRem(208),
          }}
          strokeWidth="0.5"
        />
        {mainIcon}
      </div>
      {loadingText && (
        <Stack width="100%" position="absolute" top="60%">
          <Typography py={1} textAlign="center">
            {loadingText}
          </Typography>
        </Stack>
      )}
      <Stack
        justifyContent="flex-end"
        sx={{ height: "100%", p: 2, width: "100%" }}
      >
        {children}
      </Stack>
    </Backdrop>
  ) : null;
}
