import * as Sentry from "@sentry/react";

export function initialiseApplicationMonitoring() {
  if (import.meta.env.VITE_APP_SENTRY_DSN?.length) {
    Sentry.init({
      dsn: import.meta.env.VITE_APP_SENTRY_DSN,
      environment: import.meta.env.VITE_APP_SENTRY_ENVIRONMENT,
      maxValueLength: 5000,
      integrations: [Sentry.browserTracingIntegration()],
      tracesSampleRate: Number(
        import.meta.env.VITE_APP_SENTRY_TRACES_SAMPLE_RATE
      ),
    });
  }
}

export function captureException(err: any) {
  Sentry.captureException(err);
}
