import { Box } from "@mui/material";
import { clampNumber } from "../../../../utils";

type StepGap = "small" | "medium";

interface SteppedProgressIndicatorProps {
  value?: number;
  max?: number;
  isComplete?: boolean;
  animate?: boolean;
  animationDuration?: number;
  stepGap?: StepGap;
}

export default function SteppedProgressIndicator({
  value = 0,
  max = 0,
  isComplete = false,
  animate = false,
  animationDuration = 3,
  stepGap = "small",
}: SteppedProgressIndicatorProps) {
  const maxNormalised = max > 0 ? max : 1;
  const valueNormalised = clampNumber(value, 0, maxNormalised);
  const stepGapValues = {
    small: 0.375,
    medium: 1,
  };
  const stepGapMultiplier = stepGapValues[stepGap];

  return (
    <Box
      role="progressbar"
      aria-label="Progress bar indicator"
      aria-valuenow={valueNormalised}
      aria-valuemax={maxNormalised}
      data-is-complete={isComplete}
      sx={{
        display: "flex",
        flexDirection: "row",
        gap: (theme) => theme.spacing(stepGapMultiplier),
        height: (theme) => theme.spacing(0.375),

        "& > *": {
          backgroundColor: "grey.300",
          borderRadius: (theme) => theme.spacing(0.375),
          flexGrow: 1,
          overflow: "hidden",
          "&[data-is-active='true']": {
            backgroundColor: !animate ? "primary.main" : "rgba(33, 33, 33, .3)",
          },
          "&[data-is-active='false']": {
            backgroundColor: !animate ? "grey.300" : "transparent",
          },
        },

        "&[data-is-complete='true'] > *": {
          backgroundColor: !animate ? "success.light" : "rgba(33, 33, 33, .3)",
        },

        ".progress-bar-indicator": {
          display: "block",
          height: "100%",
          "&[data-is-animated='true']": {
            animation: `moveIndicator ${animationDuration}s 1 linear`,
          },
          "&[data-is-active='true']": {
            backgroundColor: "grey.900",
          },
          "&[data-is-active='false']": {
            backgroundColor: "grey.900",
            opacity: 0.3,
          },
        },

        "@keyframes moveIndicator": {
          "0%": { width: "0%" },
          "100%": { width: "100%" },
        },
      }}
    >
      {Array.from(Array(maxNormalised).keys()).map((_item, index) => {
        return (
          <i
            key={String(_item + index)}
            data-is-active={index < valueNormalised ? true : false}
          >
            {animate && (
              <i
                key={String(_item + index)}
                className="progress-bar-indicator"
                data-is-active={index < valueNormalised ? true : false}
                data-is-animated={index === valueNormalised - 1 ? true : false}
              ></i>
            )}
          </i>
        );
      })}
    </Box>
  );
}
