export const options = {
  maxLength: 14,
  minLength: 4,
};

export function execute(value: string | unknown): string[] {
  const errorMessages = {
    INVALID_TYPE: "INVALID_TYPE",
    TOO_SHORT: "TOO_SHORT",
    TOO_LONG: "TOO_LONG",
  };

  const errors: string[] = [];

  if (typeof value !== "string") {
    errors.push(errorMessages.INVALID_TYPE);
    return errors;
  }

  if (value.length < options.minLength) {
    errors.push(errorMessages.TOO_SHORT);
  }

  if (value.length > options.maxLength) {
    errors.push(errorMessages.TOO_LONG);
  }

  return errors;
}

export function isValid(value: string | unknown): boolean {
  return execute(value).length === 0;
}
