import {
  Box,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  TextField,
} from "@mui/material";
import {
  FeatureToggleIdentifier,
  isFeatureEnabled,
} from "../../../../services/feature-toggle-adapter";
import { useEffect, useId, useState } from "react";
import {
  Country,
  findCountryBasedOnCountryCode,
  supportedCountries,
  useGlobalStore,
} from "../../../../store";
import {
  isValid,
  options as InternationalPhoneNumberValidatorOptions,
} from "../../../../utils/validators/InternationalPhoneNumber/InternationalPhoneNumber";
import { useTranslation } from "react-i18next";
import {
  styleInputDefault,
  styleInputPrimaryCenteredBold,
} from "../../../../theme";
import { convertPxToRem, normaliseStringToNumber } from "../../../../utils";

interface CountrySelectItemProps {
  country: Country;
  includeLabel?: boolean;
}

function CountrySelectItem({ country, includeLabel }: CountrySelectItemProps) {
  const text = includeLabel
    ? `${country.internationalDiallingCode} ${country.label}`
    : `${country.internationalDiallingCode}`;

  return (
    <>
      <Box
        component="img"
        bgcolor={"neutral.300"}
        alt={country.id}
        minHeight={convertPxToRem(12)}
        minWidth={convertPxToRem(16)}
        src={`/countries/${country.id.toUpperCase()}.svg`}
        mr={0.5}
      />
      {text}
    </>
  );
}

export interface InternationalPhoneNumber {
  countryCode: string;
  globalSubscriberNumber: string;
}

interface InternationalPhoneNumberInputProps {
  value: InternationalPhoneNumber;
  onChange: (value: InternationalPhoneNumber, isValid: boolean) => void;
  isDisabled?: boolean;
  isDialingCodeDropDownDisabled?: boolean;
  size?: "medium" | "small";
  inputStylesOverride?: any;
}

export default function InternationalPhoneNumberInput(
  props: InternationalPhoneNumberInputProps
) {
  const inputID = useId();
  const { state, dispatch } = useGlobalStore();

  const [phoneNumber, setPhoneNumber] = useState<InternationalPhoneNumber>({
    countryCode: props.value.countryCode || "",
    globalSubscriberNumber: props.value?.globalSubscriberNumber || "",
  });
  const [isCountryCodeValueEmpty, setIsCountryCodeValueEmpty] = useState(true);
  const { t } = useTranslation();

  const isDiallingCodeDropdownVisible = isFeatureEnabled(
    FeatureToggleIdentifier.ENABLE_INTERNATIONAL_PHONE_NUMBER
  );

  const [filteredSupportedCountries, setFilteredSupportedCountries] =
    useState<any[]>(supportedCountries);

  function onPhoneNumberInput(event: React.ChangeEvent<HTMLInputElement>) {
    const { value } = event.target;
    const sanitisedValue = normaliseStringToNumber(value);
    setPhoneNumber((prevValue) => ({
      ...prevValue,
      globalSubscriberNumber: sanitisedValue,
    }));
  }

  function onCountryCodeChange(event: SelectChangeEvent<string>) {
    const { value } = event.target;
    setPhoneNumber((prevValue) => ({
      ...prevValue,
      countryCode: value,
    }));
  }

  useEffect(() => {
    setIsCountryCodeValueEmpty(
      phoneNumber && phoneNumber.countryCode.length < 1
    );
    if (
      !isFeatureEnabled(
        FeatureToggleIdentifier.ENABLE_INTERNATIONAL_PHONE_NUMBER_UG
      ) &&
      !state.isThirdPartyMember
    ) {
      setFilteredSupportedCountries(
        supportedCountries.filter(
          (supportedCountry) => supportedCountry.id !== "UG"
        )
      );
    }
    if (isDiallingCodeDropdownVisible) {
      dispatch({
        type: "SET_COUNTRY_CODE",
        payload: phoneNumber.countryCode,
      });

      props.onChange(
        phoneNumber,
        isValid(phoneNumber.globalSubscriberNumber) &&
          phoneNumber.countryCode.length > 0
      );
    } else {
      props.onChange(phoneNumber, isValid(phoneNumber.globalSubscriberNumber));
    }
  }, [phoneNumber]);

  return (
    <Stack direction="row">
      {isDiallingCodeDropdownVisible && (
        <Select
          value={
            isCountryCodeValueEmpty
              ? t("common.selectPlaceholder")
              : phoneNumber.countryCode
          }
          disabled={props.isDisabled || props.isDialingCodeDropDownDisabled}
          size={props.size}
          sx={{
            borderBottomRightRadius: 0,
            borderTopRightRadius: 0,
            maxWidth: (theme) => theme.spacing(14),
            minWidth: (theme) => theme.spacing(14),
            fontSize: isCountryCodeValueEmpty ? convertPxToRem(14) : null,
          }}
          onChange={onCountryCodeChange}
          renderValue={(value) => {
            if (isCountryCodeValueEmpty) {
              return t("common.selectPlaceholder");
            }
            const foundCountry = findCountryBasedOnCountryCode(value);
            return <CountrySelectItem country={foundCountry} />;
          }}
          inputProps={{
            "aria-label": t("CollectPhoneNumber.DiallingCodeDropdownLabel"),
          }}
          MenuProps={{
            "aria-label": t("CollectPhoneNumber.DiallingCodeDropdownLabel"),
          }}
        >
          {filteredSupportedCountries.map((supportedCountry: Country) => (
            <MenuItem
              aria-label={`${supportedCountry.label}`}
              key={supportedCountry.label + supportedCountry.id}
              value={supportedCountry.id}
            >
              <CountrySelectItem
                country={supportedCountry}
                includeLabel={true}
              />
            </MenuItem>
          ))}
        </Select>
      )}
      <TextField
        id={inputID}
        value={phoneNumber.globalSubscriberNumber}
        disabled={props.isDisabled}
        size={props.size}
        onInput={onPhoneNumberInput}
        inputProps={{
          autoComplete: "off",
          inputMode: "numeric",
          maxLength: InternationalPhoneNumberValidatorOptions.maxLength,
          "aria-label": t("CollectPhoneNumber.CellphoneNumberInputLabel"),
        }}
        sx={{
          fieldset: isDiallingCodeDropdownVisible
            ? {
                borderBottomLeftRadius: 0,
                borderTopLeftRadius: 0,
                borderLeftWidth: 0,
              }
            : {},
          input: props.inputStylesOverride
            ? props.inputStylesOverride
            : {
                ...styleInputPrimaryCenteredBold,
                "&::placeholder": {
                  color: "neutral.500",
                  fontSize: styleInputDefault.fontSize,
                  fontWeight: 500,
                  textAlign: "left",
                },
              },
          flexGrow: 2,
        }}
        placeholder={t("CollectPhoneNumber.inputPlaceholder")}
        fullWidth
      />
    </Stack>
  );
}
