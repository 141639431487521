const PERSISTENCE_PREFIX = "UNU_HEALTH_";

let _localStorage: any = {};

try {
  window.localStorage.setItem(
    "_TEMPORARY_KEY_TO_TEST_SUPPORT_FOR_LOCAL_STORAGE_",
    "1"
  );
  window.localStorage.removeItem(
    "_TEMPORARY_KEY_TO_TEST_SUPPORT_FOR_LOCAL_STORAGE_"
  );
  _localStorage = window.localStorage;
} catch (error) {
  _localStorage = {
    _data: {},
    setItem: function (key: string, value: any) {
      this._data[key] = String(value);
      return this._data[key];
    },
    getItem: function (key: string) {
      return this._data.hasOwnProperty(key) ? this._data[key] : undefined;
    },
    removeItem: function (key: string) {
      return delete this._data[key];
    },
    clear: function () {
      this._data = {};
      return this._data;
    },
  };
}

export function persistValue(key: string, value: any) {
  const stringifiedValue = JSON.stringify(value);
  _localStorage.setItem(PERSISTENCE_PREFIX + key, stringifiedValue);
}

export function clearValue(key: string) {
  _localStorage.removeItem(PERSISTENCE_PREFIX + key);
}

export function retrieveValue(key: string): any {
  try {
    return JSON.parse(_localStorage.getItem(PERSISTENCE_PREFIX + key) || "");
  } catch {
    return null;
  }
}
